import { Fragment, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  FormFeedback,
  ModalFooter,
  Alert,
  Spinner,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import "@styles/react/libs/react-select/_react-select.scss";
import Select from "react-select";
import { toast } from "react-hot-toast";
import axios from "axios";

const UpdateRecursionModal = ({ show, onClose, agreement }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const recursion = [
    { label: "Monthly", value: "1" },
    { label: "2 Months", value: "2" },
    { label: "3 Months", value: "3" },
    { label: "6 Months", value: "6" },
    { label: "Yearly", value: "12" },
  ];

  const {
    control,
    setError,
    clearErrors,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      recursion: { label: "Monthly", value: "1" },
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      if (!agreement) {
        toast.error("Agreement is required");
        return;
      }

      const response = await axios.patch(
        `/agreements/${agreement?._id}/update-recursion`,
        { recursion: data.recursion?.value }
      );

      if (response.status === 200) {
        toast.success("Recursion updated successfully!");
        reset();
        onClose();
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ??
        error?.response?.data ??
        error.message ??
        "Something went wrong, please try again";

      setErrorMessage(errorMessage);
    }finally{
      setLoading(false);
    }
    
  };

  const onDiscard = () => {
    clearErrors();
    reset();
    onClose();
    setErrorMessage(null);
  };

  // when agreement is changes updtae the default values
  // the agreement.recursion is only contains id like 1
  useEffect(() => {
    if (agreement) {
      const currentRecursion = recursion.find(
        (rec) => rec.value === agreement.recursion
      );
      reset({
        recursion: currentRecursion,
      });
    }
  }, [agreement]);

  return (
    <Fragment>
      <Modal
        isOpen={show}
        onClosed={onDiscard}
        toggle={onClose}
        className=""
        centered
      >
        <ModalHeader toggle={onClose} className="bg-white">
          Update Recursion
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="">
            {errorMessage && (
              <Alert color="danger" className="px-2 py-1">
                {errorMessage}
              </Alert>
            )}

            <Row className="gy-1 gx-2 flex-column mb-1">
              <Col xs={12}>
                <Label className="form-label" for="recursion">
                  Recursion
                </Label>
                <Controller
                  name="recursion"
                  control={control}
                  rules={{ required: "Please select a recursion option" }}
                  render={({ field }) => (
                    <Select
                      id="recursion"
                      options={recursion}
                      isClearable
                      classNamePrefix="select"
                      {...field}
                    />
                  )}
                />
                {errors.recursion && (
                  <FormFeedback className="d-block">
                    {errors.recursion.message}
                  </FormFeedback>
                )}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <Button
              type="submit"
              className="me-1"
              color="success"
              disabled={loading}
            >
              <Spinner
                color="light"
                size="sm"
                className="me-1"
                hidden={!loading}
              />
              {loading ? "Submitting..." : "Submit"}
            </Button>
            <Button
              type="reset"
              className=""
              color="secondary"
              outline
              onClick={onDiscard}
            >
              Close
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default UpdateRecursionModal;
