import moment from "moment/moment";
import { Fragment, useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { ChevronDown } from "react-feather";
import ReactPaginate from "react-paginate";
import generatePDF, { Margin, Resolution } from "react-to-pdf";
import { useReactToPrint } from "react-to-print"; // Import react-to-print
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardTitle
} from "reactstrap";
import { formatCurrency } from "../../../utility/Utils";
import RentInvoiceModel from "../../invoices/components/generateInvoice";
import MergeInvoice from "./MergeInvoice";
import jsPDF from "jspdf";




const Invoice = ({ store }) => {
  const [sort, setSort] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const [modelShow, setModelShow] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);

  // Create a reference for MergeInvoice component
  const mergeInvoiceRef = useRef(null);

  const dataToRender = () => {
    return invoices;
  };

  const columns = [
    {
      name: "#",
      sortable: true,
      selector: (row) => row.invoiceNo,
      cell: (row) => (
        <span className="fw-bolder text-primary">{row.invoiceNo ?? ""}</span>
      ),
    },
    {
      name: "Date",
      selector: (row) => row.createdAt,
      cell: (row) => (
        <span className="text-capitalize">
          {moment(row.createdAt).format("DD-MMM-YYYY")}
        </span>
      ),
    },
    {
      name: "Type",
      selector: (row) => row.type,
      cell: (row) => <span className="text-capitalize">{row.type}</span>,
    },
    {
      name: "Total",
      selector: (row) => row.total,
      cell: (row) => (
        <span color="primary" className="text-capitalize fw-bolder">
          {formatCurrency(row.grandTotal)}
        </span>
      ),
    },
    {
      name: "Balance",
      selector: (row) => row.balance,
      cell: (row) => (
        <span className="text-success fw-bolder">${row.balance ?? 0}</span>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.balance,
      cell: (row) => (
        <Badge
          color={`${row.status === "paid"
            ? "success"
            : row.status === "partial"
              ? "warning"
              : "danger"
            }`}
        >
          {row.status}
        </Badge>
      ),
    },
    {
      name: "User",
      selector: (row) => row.createdBy,
      cell: (row) => row.createdBy?.username,
    },
  ];

  const handlePagination = (page) => {
    const startIndex = page.selected * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentPageData = store.data.invoices.slice(startIndex, endIndex);

    setInvoices(currentPageData);
    setCurrentPage(page.selected + 1);
  };

  useEffect(() => {
    setInvoices(store.data.invoices?.slice(0, rowsPerPage));
  }, [store]);

  // Handle row selection
  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedInvoices(selectedRows);
  };

  const CustomPagination = () => {
    const count = Number(Math.ceil(store.data.invoices.length / rowsPerPage));
    return (
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        pageCount={count || 1}
        activeClassName="active"
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName={"page-item"}
        nextLinkClassName={"page-link"}
        nextClassName={"page-item next"}
        previousClassName={"page-item prev"}
        previousLinkClassName={"page-link"}
        pageLinkClassName={"page-link"}
        containerClassName={
          "pagination react-paginate justify-content-end my-2 pe-1"
        }
      />
    );
  };

  const ToggleRentModal = (show) => {
    setModelShow(false);
  };

  // Handle combine invoices button click
  const handleCombineInvoices = () => {
    // Implement logic to combine the selected invoices
    console.log("Combine the following invoices: ", selectedInvoices);
  };

  // Handle print logic for MergeInvoice component
  const handlePrint = useReactToPrint({
    content: () => mergeInvoiceRef.current,
    documentTitle: "Merged Invoices",
    pageStyle: `
      @page {
        size: A4 portrait;
        margin: 10mm !important;
      }
      @media print {
        html, body {
          height: auto !important;
          overflow: visible !important;
          -webkit-print-color-adjust: exact;
        }
      }
    `,
  });


  const handleDownload = () => {
    const element = mergeInvoiceRef.current;
  
    // Temporarily make the element visible
    element.style.display = "block";
  
    // Use generatePDF to create the PDF
    const options = {
      filename: 'merged-invoices.pdf',
      resolution: Resolution.HIGH,
      page: {
        margin: Margin.SMALL,
      },
      canvas: {
        qualityRatio: 1,
      },
      overrides: {
        pdf: {
          compress: true,
        },
        canvas: {
          useCORS: true,
        },
      },
    };
  
    generatePDF(mergeInvoiceRef, options)
      .then(() => {
        // Hide the element again after the PDF is generated
        element.style.display = "none";
      })
      .catch((error) => {
        console.error("Failed to generate PDF:", error);
        element.style.display = "none"; // Ensure it's hidden in case of an error
      });
  };
  

  const handleShareWhatsApp = () => {
    const pdf = new jsPDF();

    // Add content to PDF
    pdf.text("Invoice Details", 10, 10);

    // Generate Blob
    const pdfBlob = pdf.output("blob");

    if (pdfBlob) {
      const blobURL = URL.createObjectURL(pdfBlob);

      // WhatsApp share link
      const whatsappMessage = encodeURIComponent(
        "Please find the attached invoice PDF."
      );
      const whatsappLink = `https://wa.me/?text=${whatsappMessage}&url=${blobURL}`;

      // Open WhatsApp link
      window.open(whatsappLink, "_blank");

      // Optional: Automatically download the PDF
      const downloadLink = document.createElement("a");
      downloadLink.href = blobURL;
      downloadLink.download = "invoice.pdf";
      downloadLink.click();
    } else {
      console.error("Failed to generate PDF");
    }
  };



  return (
    <Fragment>
      <RentInvoiceModel show={modelShow} onClose={ToggleRentModal} />
      <Card>
        <CardHeader
          tag="h4"
          className="d-flex justify-content-between align-items-center"
        >
          <CardTitle>Customers' Invoices List</CardTitle>
          {/* Place both buttons inside a flex container */}
          <div>
            <Button
              color="primary"
              onClick={() => setModelShow(true)}
              className="me-1"
            >
              New Invoice
            </Button>
            {selectedInvoices.length > 0 && (
              <>
                <Button color="warning" onClick={handlePrint} className="me-1">
                  Print Invoices
                </Button>
                <Button color="success" onClick={handleDownload} className="me-1">
                  Download Invoices
                </Button>
                {/* <Button color="info" onClick={handleShareWhatsApp}>
                  Share on WhatsApp
                </Button> */}
              </>
            )}
          </div>

        </CardHeader>
        <div className="react-dataTable user-view-account-projects">
          <DataTable
            subHeader
            sortServer
            pagination
            responsive
            paginationServer
            striped
            columns={columns}
            sortIcon={<ChevronDown />}
            className="react-dataTable react-dataTable-selectable-rows"
            paginationComponent={CustomPagination}
            data={dataToRender()}
            selectableRows
            onSelectedRowsChange={handleSelectedRowsChange} // Track selected rows
          />
        </div>

        {/* MergeInvoice component is now hidden from screen but available for print */}
        <div
          ref={mergeInvoiceRef}
          style={{ display: "none" }}
          className=" d-print-block"
        >
          <MergeInvoice invoiceData={selectedInvoices} ref={mergeInvoiceRef} />
        </div>
      </Card>
    </Fragment>
  );
};

export default Invoice;