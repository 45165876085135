import {
  Archive,
  Bank,
  Building,
  BuildingAdd,
  BuildingDash,
  EnvelopePaper,
  Grid,
  House,
  JournalText,
  ListColumnsReverse,
  People,
  Person,
  PersonGear,
  Receipt,
  Stickies,
  UiChecks,
} from "react-bootstrap-icons";

import {
  Book,
  Circle,
  CreditCard,
  Layers,
  Settings,
  Slack,
  Target,
  Users,
} from "react-feather";

export default [
  {
    header: "Dashboard",
    view: ["maintainance", "salesAndBroker", "consultation", "company"],
  },
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <House size={20} />,
    navLink: "/dashboard",
    action: ["read"],
    resource: "Dashboard",
    view: ["maintainance", "salesAndBroker", "consultation", "company"],
  },

  {
    header: "Property",
    view: ["maintainance", "salesAndBroker", "consultation", "company"],
  },

  {
    id: "units",
    title: "Units",
    icon: <BuildingAdd size={20} />,
    navLink: "/units",
    action: ["read", "create", "update", "delete"],
    resource: "Sectional",
    view: ["maintainance", "salesAndBroker", "consultation", "company"],
  },

  {
    id: "units-category",
    title: "Units Category",
    icon: <BuildingAdd size={20} />,
    navLink: "/units-category",
    action: ["read", "create", "update", "delete"],
    resource: "SectionalCategory",
    view: ["company"],
  },

  {
    id: "properties",
    title: "Properties",
    icon: <BuildingDash size={20} />,
    navLink: "/properties",
    action: ["read", "create", "update", "delete"],
    resource: "Building",
    view: ["salesAndBroker", "company", "!default"],
  },
  {
    id: "categories",
    title: "Categories",
    icon: <Grid size={20} />,
    navLink: "/categories",
    action: ["read", "create", "update", "delete"],
    resource: "Category",
    view: ["salesAndBroker", "company", "!default"],
  },
  {
    id: "amenities",
    title: "Amenities",
    icon: <Slack size={20} />,
    navLink: "/amenities",
    action: ["read", "create", "update", "delete"],
    resource: "Amenity",
    view: ["salesAndBroker", "company", "!default"],
  },

  {
    header: "People",
    view: ["maintainance", "salesAndBroker", "consultation", "company"],
  },

  {
    id: "tenants",
    title: "Tenants",
    icon: <People size={20} />,
    navLink: "/customers",
    action: ["read", "create", "update", "delete"],
    resource: "Customer",
    view: ["maintainance", "salesAndBroker", "consultation"],
  },
  {
    id: "owners",
    title: "Owners",
    icon: <Person size={20} />,
    navLink: "/owners",
    action: ["read", "create", "update", "delete"],
    resource: "Owner",
    view: [
      "maintainance",
      "salesAndBroker",
      "consultation",
      "!default",
      "company",
    ],
  },
  {
    id: "vendors",
    title: "Vendors",
    icon: <Target size={20} />,
    navLink: "/vendors",
    action: ["read", "create", "update", "delete"],
    resource: "Vendor",
    view: ["!default", "company"],
  },
  {
    id: "users",
    title: "Users",
    icon: <Users size={20} />,
    navLink: "/users",
    action: ["read", "create", "update", "delete"],
    resource: "User",
    view: ["company", "!default"],
  },

  {
    id: "hrm",
    title: "HRM",
    icon: <PersonGear size={20} />,
    children: [
      {
        id: "staff",
        title: "Staff",
        icon: <Circle size={12} />,
        navLink: "/hrm/staff",
        action: ["read", "create", "update", "delete"],
        resource: "Staff",
      },
      {
        id: "jobs",
        title: "Jobs",
        icon: <Circle size={12} />,
        navLink: "/hrm/jobs",
        action: ["read", "create", "update", "delete"],
        resource: "Job",
      },

      {
        id: "leaves",
        title: "Leaves",
        icon: <Circle size={20} />,
        navLink: "/hrm/leaves",
        action: ["read", "create", "update", "delete"],
        resource: "LeaveRequest",
      },
      // {
      //   id: "payroll",
      //   title: "Payroll",
      //   icon: <Circle size={20} />,
      //   navLink: "/hrm/payroll",
      //   action: ["read", "create", "update", "delete"],
      //   resource: "Payroll",
      // },
    ],
  },

  //

  {
    header: "BOOKING",
    view: ["salesAndBroker", "maintainance", "consultation"],
  },
  {
    id: "rentalApplication",
    title: "Rental Applications",
    icon: <EnvelopePaper size={20} />,
    navLink: "/rental_applications",
    action: ["read", "create", "update", "delete", "approve", "cancel"],
    resource: "Booking",
  },
  {
    id: "leases",
    title: "Leases",
    icon: <ListColumnsReverse size={20} />,
    navLink: "/leases",
    action: [
      "read",
      "create",
      "update",
      "delete",
      "renew",
      "terminate",
      "view",
    ],
    resource: "Agreement",
  },

  // {
  //   header: "Services",
  // },

  {
    header: "Accounting",
    view: ["maintainance", "salesAndBroker", "consultation", "company"],
  },

  {
    id: "invoices",
    title: "Invoices",
    icon: <JournalText size={20} />,
    navLink: "/invoices",
    action: ["read", "create", "update", "delete"],
    resource: "Invoice",
    view: ["maintainance", "salesAndBroker", "consultation"],
  },

  {
    id: "receipts",
    title: "Receipts",
    icon: <Receipt size={20} />,
    navLink: "/receipts",
    action: ["read", "create", "update", "delete"],
    resource: "Receipt",
    view: ["maintainance", "salesAndBroker", "consultation"],
  },

  {
    id: "expense",
    title: "Expenses",
    icon: <CreditCard size={20} />,
    navLink: "/expenses",
    action: ["read", "create", "update", "delete"],
    resource: "Expense",
    view: ["maintainance", "salesAndBroker", "consultation"],
  },

  {
    id: "utilities",
    title: "Utilities",
    icon: <Archive size={20} />,
    navLink: "/utility/type",
    children: [
      {
        id: "utilityTypes",
        title: "Utility Types",
        icon: <Circle size={12} />,
        navLink: "/utility/type",
        resource: "UtilityType",
        action: ["read", "create", "update", "delete"],
      },
      {
        id: "Utility",
        title: "Utility List",
        icon: <Circle size={12} />,
        navLink: "/utilities",
        resource: "Utility",
        action: ["read", "create", "update", "delete"],
      },
    ],
  },

  // {
  //   id: "payment",
  //   title: "Payments",
  //   icon: <ShoppingBag size={20} />,
  //   navLink: "/payments",
  //   action: "read",
  //   resource: "Payment",
  // },
  {
    id: "Accounting Books",
    title: "Accounting Books",
    icon: <Book size={20} />,
    navLink: "/accountings/books",
    action: ["read", "create", "update", "delete"],
    resource: "Accounting_books",
    view: ["company",  ],
  },
  {
    id: "account",
    title: "Chart of Accounts",
    icon: <Bank size={20} />,
    navLink: "/accounts",
    action: ["read", "create", "update", "delete"],
    resource: "Account",
    view: ["company",  ],
  },
  {
    header: "Reports",
    view: ["salesAndBroker", "maintainance", "consultation", "company"],
  },
  {
    id: "reports",
    title: "Reports",
    icon: <Stickies size={20} />,
    navLink: "/reports",
    action: ["read", "create", "update", "delete"],
    resource: "Reports",
    view: ["salesAndBroker", "maintainance", "consultation", "company"],
  },
  {
    id: "financial-reports",
    title: "Financial Reports",
    icon: <Layers size={20} />,
    view: [
      "salesAndBroker",
      "maintainance",
      "consultation",
      
      "company",
    ],
    children: [
      {
        id: "income-statement",
        title: "Income Statement",
        icon: <Circle size={12} />,
        action: ["read"],
        resource: "IncomeStatement",
        navLink: "/accountings/income-statement",
      },
      {
        id: "trial-statement",
        title: "Trial Balance",
        icon: <Circle size={12} />,
        action: ["read"],
        resource: "TrailStatement",
        navLink: "/accountings/trial-balance",
      },
      {
        id: "balance-statement",
        title: "Balance Sheet",
        icon: <Circle size={12} />,
        action: ["read"],
        resource: "BalanceStatement",
        navLink: "/accountings/balance-sheet",
      },

      {
        id: "account-statement",
        title: "Account Statement",
        icon: <Circle size={12} />,
        action: ["read"],
        resource: "AccountStatement",
        navLink: "/accountings/account-statement",
      },
      {
        id: "ledger",
        title: "Ledger",
        icon: <Circle size={12} />,
        action: ["read"],
        resource: "Ledger",
        navLink: "/accountings/ledger",
      },
    ],
  },

  {
    header: "Settings",
    view: ["company", "!default"],
  },
  {
    id: "roles",
    title: "Roles & Permissions",
    icon: <UiChecks size={20} />,
    navLink: "/roles",
    action: ["read", "create", "update", "delete"],
    resource: "Role",
    view: ["company", "!default"],
  },
  {
    id: "settings",
    title: "Setting",
    icon: <Settings size={20} />,
    navLink: "/settings",
    action: ["read"],
    resource: "Setting",
    view: ["company", "!default"],
  },
];
